
import Vue from "@/interfaces/vue";
import { Component, Prop } from "vue-property-decorator";
import { TherapistInfo } from "@/interfaces/therapist";
import { get } from "@/api";

@Component({})
export default class DesktopInfoFillingView extends Vue {
  firstTime = false;
  therapist: null | TherapistInfo = null;

  created() {
    if (!this.$store.state.isFilled) this.firstTime = true;
    this.getTherapistData();
  }

  async getTherapistData() {
    try {
      const { data } = await get.therapistInfo();
      this.therapist = data;
    } catch (err) {
      console.log(err);
    }
  }

  get title() {
    switch (this.$route.path) {
      case "/dashboard/myprofile/personal":
        return this.$t("settings.personal-data");
      case "/dashboard/myprofile/background":
        return this.$t("settings.background");
      case "/dashboard/myprofile/office":
        return this.$t("settings.office-information");
      case "/dashboard/myprofile/availability":
        return this.$t("settings.aviability");
      case "/dashboard/myprofile/billing":
        return this.$t("settings.billinginfo.title");
    }
    return "";
  }
}
